@import '../styles/variable';

.page-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;

    .title {
        font-size: 13px;
        text-transform: uppercase;
        color: $color-text-cyan;
        font-weight: 600;
        line-height: 15.23px;
    }

    .ds-header {
        padding: 10px 12px 3px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left-content-container {
        width: 45%;
        background-color: white;

        .ds-search-box {
            display: flex;
            padding: 10px 12px;
            align-items: center;
            gap: 10px;

            .box-search {
                flex: 1;
            }
        }

        .status-box {
            padding: 16px 24px;
            display: flex;
            gap: 10px;
            flex-direction: column;

            .status-box-item {
                display: flex;
                align-items: center;
                min-width: 140px;
                gap: 10px;
            }
        }
    }

    .right-content-container {
        width: 55%;

        .tt-header {
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            background: white;
            display: flex;
            margin-bottom: 6px;
        }

        .tt-tabs {
            .customs-tabs {
                height: 41px;
                padding: 8px 10px 0 8px;

                .nav-link {
                    height: 33px;
                }
            }

            .tab-content .tab-pane {
                height: calc(100vh - 155px);
                background: $color-white !important;
            }
        }

        .orther-info-tab-container {
            .main-content-title {
                font-size: 14px;
                font-weight: bold;
                padding: 8px 12px;
                color: #1a5e83;
            }
        }
    }

}

.modal-fullscreen {
    margin: 20px 0;
    height: calc(100vh - 40px);

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        max-height: calc(100vh - 177px);
        overflow: auto;
    
        .form-child-container {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
    .logo{
        width: 30px ;
        height: auto ;
        margin-right: -4px;
    }
    .modal-footer {
        padding: 10px;
    }

    .img-box-container {
        position: relative;

        .btn-remove-img {
            position: absolute;
            top: -9px;
            right: -9px;
            border-radius: 999px;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            background-color: #ccc;
            cursor: pointer;
        }
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    max-height: calc(100vh - 250px);
    overflow: auto;
}

.group-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 28px;
}

.bao-cao-scyk-container {
    width: 100vw;
    height: 100vh;
    background-color: $color-white;
    color: $color-dark;

    .modal-fullscreen {
        margin: 0;
    }

    .form-control {
        color: #5E6278;
        background-color: $color-white;
    }

    .form-container {
        max-height: calc(100vh - 60px);
    }

    .radio-group-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .radio-group-wrapper {
            width: 50%;
        }
    }

    .img-box-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        border: 1px #ccc solid;
    }
}

@media only screen and (max-width: 768px) {
    .bao-cao-scyk-container {
        .img-box-container {
            width: 32%;
        }
    }
}

@media only screen and (max-width: 425px) {
    .bao-cao-scyk-container {
        .radio-group-container {
            flex-direction: column;

            .radio-group-wrapper { 
                width: 100%;
            }
        }

        .img-box-container {
            width: 48%;
        }

        .hinh-thuc-bao-cao {
            flex-direction: column;
            gap: 10px !important;
        }
        .form-child-container {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .loai-nbc span:nth-child(2) {
                flex: 100%;
                padding-right: 0px;
            }
        }
    }
}